<template>
  <div>
    <el-dialog
      :custom-class="'user-dialog'"
      :visible="dialogvisible"
      width="70%"
      @close="$emit('update:dialogvisible', false)"
    >
      <div class="avatar-box">
        <p
          style="
            text-align: center;
            padding-top: 110px;
            color: white;
            font-size: 25px;
          "
        ></p>
      </div>
      <h3 class="text-center m-0 p-10">
        {{ data.projeAdi }} Adlı Projeye Ait Tutanak Listesi
      </h3>
      <el-divider />
      <div :class="isDesktop ? 'right-box box grow' : ''"></div>
      <div class="tutanak-list-area">
        <el-table :data="data.tutanakList" style="width: 100%">
          <el-table-column
            prop="sayi"
            label="Sayı"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="konu"
            label="Konu"
            width="180"
          ></el-table-column>
          <el-table-column prop="icerik" label="İçerik"></el-table-column>
          <el-table-column
            prop="yetkiliAdSoyad"
            label="Yetkili"
            width="180"
          ></el-table-column>
          <el-table-column prop="iletisimTuru" label="Tarih" width="100">
            <template slot-scope="scope">
              {{
                scope.row.iletisimTuru == "1"
                  ? "Telefon"
                  : scope.row.iletisimTuru == "2"
                  ? "E-Posta"
                  : scope.row.iletisimTuru == "3"
                  ? "Toplantı"
                  : "Yüz Yüze"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="yer" label="Yer" width="100"></el-table-column>
          <el-table-column prop="olusumTarihi" label="Tarih" width="150">
            <template slot-scope="scope">
              {{ scope.row.olusumTarihi.slice(0, 16) }}
            </template>
          </el-table-column>
          <el-table-column prop="tutanakID" label="İşlem" width="150">
            <template slot-scope="scope">
              <el-tooltip
                content="Tutanak Detay"
                :open-delay="500"
                placement="left"
              >
                <el-button
                  @click="tutanakDetay(scope.row)"
                  type="text"
                  class="islem-button"
                  style="margin-right: 10px"
                  icon="mdi mdi-eye mdi-24px"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row>
        <el-col style="display: flex; justify-content: end">
          <p class="tutanak-limit-text">Son 10 Tutanak</p>
        </el-col>
      </el-row>
      <el-divider class="bottom-divider" />
      <el-row>
        <el-col class="all-tutanak-col">
          <el-button @click="goTutanakList" size="mini"
            >Projeye Ait Tüm Tutanakları İncele</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <tutanak-detay
      v-if="dialogVisibleTutanak"
      :dialogvisible.sync="dialogVisibleTutanak"
      :userdata="userDataBGSurecTutanak"
    ></tutanak-detay>
  </div>
</template>

<script>
import yoneticiService from "../WSProvider/YoneticiService";
import moment from "moment";
import functions from "@/functions";
import TutanakDetay from "@/components/TutanakDetay.vue";

export default {
  name: "TutanakListModal",
  components: { TutanakDetay },
  props: ["data", "dialogvisible"],
  data() {
    return {
      isDesktop: false,
      activeName: "first",
      dialogVisibleTutanak: false,
      userDataBGSurecTutanak: {},
    };
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    tutanakDetay(tutanak) {
      this.userDataBGSurecTutanak = tutanak;
      this.dialogVisibleTutanak = true;
    },
    goTutanakList() {
      this.$emit("update:dialogvisible", false);
      this.$store.commit("setTutanakSearchProjeID", this.data.projeID);
      functions.routeSayfa("Tutanak Listesi", this);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
  },
};
</script>

<style scoped>
.tutanak-limit-text {
  margin: 10px;
}

.tutanak-list-area {
  margin: 10px;
}

.bottom-divider {
  margin: 0 !important;
}

.all-tutanak-col button {
  margin: 10px;
}

.all-tutanak-col {
  justify-content: end;
  display: flex;
}

.all-tutanak-col {
  justify-content: end;
  display: flex;
}

.tutanak-list-area {
  min-height: 300px;
}

.avatar-box {
  margin-bottom: 10px !important;
}
</style>

<style lang="scss">
@import "../assets/scss/_variables";

.tutanak-list-modal .el-divider--horizontal {
  margin: 6px 0;
}

dl i {
  color: #2c3437;
  margin: 0 10px;
}

dl.horizontal dt {
  text-align: left;
  width: 36%;
}

dl.horizontal2 dt {
  text-align: center;
  width: 100%;
}

dl dd.tel {
  text-align: center;
}

.form-box .el-form div {
  margin: 20px 0;
}

.form-box .el-form i {
  font-size: 20px;
}

.el-dialog.user-dialog {
  margin-top: 10vh !important;

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      z-index: 1;
      background: rgba(0, 0, 0, 0.05);
      min-width: 18px;
      min-height: 18px;
      border-radius: 4px;

      .el-dialog__close {
        color: $background-color;
      }
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .form-box {
    padding: 5px;
    box-sizing: border-box;

    & > * {
      margin: 10px 0;
    }
  }
}
</style>
